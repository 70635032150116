import React, { useMemo } from "react";
import Layout from "../../components/Layout";
import Helmet from "react-helmet";
import { graphql } from "gatsby";
import { Accordion, Box, Center, Container, Divider, Flex, Image, List, Paper, Text, Title, createStyles } from "@mantine/core";
import { IconBackhoe, IconBriefcase, IconCalendar, IconTrafficCone } from "@tabler/icons-react";


const useStyles = createStyles((theme) => ({
    pageTitle: {
      fontSize: "3rem",
      //   fontVariant: "small-caps",
      "&::after": {
        content: '""',
        display: "block",
        backgroundColor: "#b28a4f",
        transition: "width 0.3s ease-in-out",
        width: 100,
        height: 2,
        marginBottom: theme.spacing.xs,
      },
      ".mantine-Paper-root:hover &::after": {
        width: "100%",
      },
    },
    card: {
          backgroundColor: "#b28a4f",
            color: "#fff",
            borderRadius: 10,
            transition: "all 0.3s ease-in-out",
            // "&:hover": {
            //     transform: "translateY(-5px)",
            //     boxShadow: "0 2px 3px rgba(0, 0, 0, 0.2)",
            // },
            // fontsize smaller on mobile
            [theme.fn.smallerThan("sm")]: {
                "& *": {
                    fontSize: "0.8rem",
                },
            }


    },

  }));

const ReferencesPage = (props) => {
  const { title } = props.data.site.siteMetadata;
  const { edges: references } = props.data.references;
  const { edges: partners } = props.data.partners;
  const { classes } = useStyles();

  const referencesWithPartners = useMemo(() => {
    return references.map(({ node: reference }) => {
      const partner = partners.find(
        ({ node: partner }) =>
          partner.frontmatter.title === reference.frontmatter.partner
      );
      return {
        ...reference.frontmatter,
        partner: partner.node.frontmatter,
      };
    });
  }, [references, partners]);

  // chunk referencesWithPartners by year of date which is a String with format: MM YYYY
  const referencesWithPartnersByDate = useMemo(() => {
    const referencesWithPartnersByDate = {};
    referencesWithPartners.forEach((reference) => {
      const year = reference.date.split(" ")[1];
      if (!referencesWithPartnersByDate[year]) {
        referencesWithPartnersByDate[year] = [];
      }
      referencesWithPartnersByDate[year].push(reference);
    });
    return referencesWithPartnersByDate;
  }, [referencesWithPartners]);

  const keysOfReferencesWithPartnersByDate = useMemo(() => {
    return Object.keys(referencesWithPartnersByDate).sort(
      (a, b) => Number(b) - Number(a)
    );
  }, [referencesWithPartnersByDate]);


  const pageTitle = useMemo(() => `${title} | Referenzen`, [title]);
  return (
    <Layout>
      <Helmet title={pageTitle} />
      <Box mt={56}  w="100%">
        <Container size={"lg"} py="xl">
        <Title
                className={classes.pageTitle}
                order={1}
                color="rgba(50, 59, 60, 0.8)"
                ff="Oswald"
                ml="sm"
            >
                Referenzen
            </Title>
            {/* <h1 >{pageTitle}</h1> */}
            <Accordion w="100%" defaultValue={keysOfReferencesWithPartnersByDate[0]}>
            {keysOfReferencesWithPartnersByDate.map((year) => {
                const references = referencesWithPartnersByDate[year];
                return (
                <Accordion.Item value={year} key={"accordion_" + year}>
                    <Accordion.Control key={"accordion_control_" + year}>
                        Jahr {year}
                    </Accordion.Control>
                    <Accordion.Panel key={"accordion_panel_" + year}>

                    {references.map((reference) => (

                        <Paper key={"referenz_" + reference.title} mb="xs" className={classes.card} shadow="xs" bg="#eee" radius="md" p="md">

                            <List>
                                <Title order={4} color="gray" mb="md">
                                    {reference.title}
                                </Title>
                                <Flex
                                    direction={"row"}
                                    align="start"
                                    gap={10}
                                    justify={"space-between"}
                                    >
                                    <List.Item
                                        style={{ flex: 1 }}
                                        icon={<IconCalendar size={24} color="gray" />}
                                        color="gray"
                                    >
                                        <Text color="dark">
                                        Ausführungszeitraum
                                        </Text>
                                    </List.Item>
                                    <Text style={{ flex: 1 }} color="gray">
                                        {String(reference.date)} -{" "}
                                        {String(
                                        reference.endDate && reference.endDate !== reference.date && reference.endDate !== "Invalid date"
                                            ? reference.endDate
                                            : "fortlaufend"
                                        )}
                                    </Text>
                                </Flex>
                                <Divider mb="sm" />
                                <Flex
                                    direction={"row"}
                                    align="start"
                                    gap={10}
                                    justify={"space-between"}
                                    >
                                    <List.Item
                                        style={{ flex: 1 }}
                                        icon={<IconBriefcase size={24} color="gray" />}
                                        color="gray"
                                    >
                                        <Text color="dark">
                                        Auftraggeber
                                        </Text>
                                    </List.Item>
                                    <Text style={{ flex: 1 }} color="gray">
                                        {reference.partner.title}
                                    </Text>
                                </Flex>
                                { reference.services &&
                                  <>
                                    <Divider mb="sm" />
                                    <Flex
                                        direction={"row"}
                                        align="start"
                                        gap={10}
                                        justify={"space-between"}
                                        >
                                    <List.Item
                                            style={{ flex: 1 }}
                                            icon={<IconBackhoe size={24} color="gray" />}
                                            color="gray"
                                        >
                                            <Text color="dark">
                                            Leistungen
                                            </Text>
                                        </List.Item>
                                    <List style={{ flex: 1 }} >
                                      {(reference.services ?? []).map((service, i) => (
                                        <List.Item
                                          icon={<IconTrafficCone size={24} color="gray" />}
                                          color="gray"
                                          key={`service_${i}`}
                                        >
                                          <Text size="sm" color="gray">
                                            {String(service.service)}
                                          </Text>
                                        </List.Item>
                                      ))}
                                    </List>
                                    </Flex>
                                  </>
                                  }
                                <Divider mb="sm" />
                                {/* <Image
                                height={50}
                                width={"auto"}
                                src={reference.partner.logoUrl}
                                alt={reference.partner.title}
                                /> */}
                            </List>
                        </Paper>
                    ))}
                    </Accordion.Panel>
                </Accordion.Item>
                );
            })}
            </Accordion>
        </Container>
      </Box>
    </Layout>
  );
};

export default ReferencesPage;

// const getPartnerByTitleQuery = async (title) => {

//         const { data } = await graphql`
//             query GetPartnerByTitleQuery($title: String!) {
//                 allMarkdownRemark(
//                     filter: { frontmatter: { templateKey: { eq: "partner-page" }, title: { eq: $title } } }
//                 ) {
//                     edges {
//                         node {
//                             frontmatter {
//                                 title
//                                 logoUrl
//                             }
//                         }
//                     }
//                 }
//             }
//         `;
//         return data;

// }

export const referencesPageQuery = graphql`
  query ReferencesPageQuery {
    site {
      siteMetadata {
        title
      }
    }
    references: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "reference-page" } } }
      sort: { order: DESC, fields: [frontmatter___date] }
    ) {
      edges {
        node {
          excerpt(pruneLength: 400)
          id
          fields {
            slug
          }
          frontmatter {
            title
            templateKey
            date(formatString: "MM YYYY")
            endDate(formatString: "MM YYYY")
            partner
            services {
              service
            }
          }
        }
      }
    }
    partners: allMarkdownRemark(
      filter: { frontmatter: { templateKey: { eq: "partner-page" } } }
      sort: { order: ASC, fields: [frontmatter___title] }
    ) {
      edges {
        node {
          frontmatter {
            title
            logoUrl
          }
        }
      }
    }
  }
`;
